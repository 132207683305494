<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-space slot="extra">
				<a-button type="primary" @click="onCreate">新增代理</a-button>
			</a-space>
			<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
				<a-row>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="ID"><a-input v-model="query.userId" placeholder="请输入ID"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="手机号码"><a-input v-model="query.mobile" placeholder="请输入手机号码"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="订单时间"><a-range-picker style="width: 100%" :value="selectedDateRange" @change="onChange" /></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="订单状态">
							<a-select style="width: 100%" v-model="query.state" placeholder="请选择订单状态" :allow-clear="true">
								<a-select-option :value="0">
									未结算
								</a-select-option>
								<a-select-option :value="1">
									已结算
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
							<a-space>
								<a-button type="primary" html-type="submit">查询</a-button>
								<a-button @click="onReset">重置</a-button>
							</a-space>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-card>

		<a-card title="区县代理管理">
			<a-space slot="extra" :size="24">
				<a-button @click="$router.replace({ name: 'countyagents' })">代理列表</a-button>
				<a-button type="primary" @click="onExport">导出</a-button>
			</a-space>
			<div class="statistics">
				<span>订单总数：{{ lists.total }}</span>
				<span>订单用户：{{ lists.countUser }}</span>
				<span>总金额：{{ lists.countMoney }}</span>
			</div>
			<a-table :data-source="lists.data" :pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }" @change="onPageChange" :scroll="{ x: 'max-content' }">
				<a-table-column key="mobile" title="用户" data-index="mobile">
					<template slot-scope="text, record">
						{{ record.orderId }}
						<br />
						{{ record.nickname }}({{ record.mobile }})
					</template>
				</a-table-column>
				<a-table-column key="money" title="金额" data-index="money">
					<template slot-scope="text, record">
						{{ record.money }}
					</template>
				</a-table-column>
				<a-table-column key="status" title="状态" data-index="status">
					<template slot-scope="status, record">
						<a-tag color="orange" v-if="status == 0">{{ record.statusStr }}</a-tag>
						<a-tag color="green" v-else-if="status == 1">{{ record.statusStr }}</a-tag>
					</template>
				</a-table-column>
				<a-table-column key="ctime" title="创建时间" data-index="ctime" />
				<a-table-column key="action" title="操作" fixed="right" :width="140">
					<template slot-scope="text, record">
						<a-space>
							<a-button size="small" type="primary" @click="onViewOrder(record)">提货订单</a-button>
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>

		<a-modal :width="600" title="订单详情" v-model="modal.visible" :footer="null">
			<a-descriptions bordered :column="1" size="small">
				<a-descriptions-item label="订单编号">
					{{ modal.data.id }}
					<a href="javascript:;" v-clipboard:copy="modal.data.id" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
				</a-descriptions-item>
				<a-descriptions-item label="订单状态">{{ modal.data.statusStr }}</a-descriptions-item>
				<a-descriptions-item label="商品名称">{{ modal.data.productName }}</a-descriptions-item>
				<a-descriptions-item label="单价">{{ modal.data.amount }}</a-descriptions-item>
				<a-descriptions-item label="数量">{{ modal.data.num }}</a-descriptions-item>
				<a-descriptions-item label="订单总金额">{{ modal.data.totalMoney }}</a-descriptions-item>
				<a-descriptions-item label="实际支付金额">{{ modal.data.totalMoney }}</a-descriptions-item>
				<a-descriptions-item label="用户信息">
					{{ modal.data.nickname }}
					<a href="javascript:;" v-clipboard:copy="modal.data.nickname" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
					(
					<span style="cursor: pointer;" @click="$router.push(`/users/${modal.data.userId}`)">{{ modal.data.mobile }}</span>
					<a href="javascript:;" v-clipboard:copy="modal.data.mobile" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
					)
				</a-descriptions-item>
				<a-descriptions-item label="用户备注">{{ modal.data.note }}</a-descriptions-item>
				<a-descriptions-item label="收货信息">
					{{ modal.data.consignee }}
					<a href="javascript:;" v-clipboard:copy="modal.data.consignee" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
					（{{ modal.data.phone }}
					<a href="javascript:;" v-clipboard:copy="modal.data.phone" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
					)
				</a-descriptions-item>
				<a-descriptions-item label="收货地址">
					{{ modal.data.province }} {{ modal.data.city }} {{ modal.data.county }} {{ modal.data.address }}
					<a href="javascript:;" v-clipboard:copy="`${modal.data.province} ${modal.data.city} ${modal.data.county} ${modal.data.address}`" v-clipboard:success="onCopySuccess">
						<a-icon type="copy" />
					</a>
				</a-descriptions-item>
				<a-descriptions-item label="订单创建时间">{{ modal.data.ctime }}</a-descriptions-item>
				<a-descriptions-item label="剩余云库存">{{ modal.data.goodsAccount }}</a-descriptions-item>
				<a-descriptions-item label="发货人">{{ modal.data.sendNickname }} （{{ modal.data.sendMobile }})</a-descriptions-item>
			</a-descriptions>
			<a-descriptions bordered :column="1" v-if="!!modal.data.trackNo" style="margin-top: 14px;">
				<a-descriptions-item label="快递单号">
					{{ modal.data.trackNo }}
					<a href="javascript:;" v-clipboard:copy="modal.data.trackNo" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
				</a-descriptions-item>
				<a-descriptions-item label="快递公司">{{ modal.data.name }}</a-descriptions-item>
				<a-descriptions-item label="发货时间">{{ modal.data.shippedTime }}</a-descriptions-item>
				<a-descriptions-item label="订单完成时间" v-if="!!modal.data.confirmTime">{{ modal.data.confirmTime }}</a-descriptions-item>
			</a-descriptions>
		</a-modal>


		<a-modal title="代理列表" :visible="$route.name == 'countyagents'" :footer="null" :width="1024" @cancel="$router.replace({name:'county'})">
			<router-view></router-view>
		</a-modal>
		
		<a-modal v-drag-modal :width="600" title="新增代理" v-model="modalAgent.visible">
			<div slot="footer">
				<a-space>
					<a-button @click="modalAgent.visible = false">取消</a-button>
					<a-button type="primary" @click="onSaveAgent">确定</a-button>
				</a-space>
			</div>
		
			<a-form-model ref="form" :model="modalAgent.formdata" :rules="modalAgent.rules" v-bind="layout">
				<a-form-model-item label="用户手机号" prop="mobile"><a-input v-model="modalAgent.formdata.mobile" placeholder="请输入用户手机号" /></a-form-model-item>
				<a-form-model-item label="区县地址" prop="county">
					<a-cascader :options="countyData" :fieldNames="{label:'name',value:'name',children:'children'}" :value="modalAgent.formdata.county ? [modalAgent.formdata.province,modalAgent.formdata.city,modalAgent.formdata.county] : [] " placeholder="请选择区县地址" @change="([ province, city, county]) => Object.assign(modalAgent.formdata, { province, city, county})" />
				</a-form-model-item>
				<a-form-model-item label="保证金" prop="payment">
					<a-row type="flex" :gutter="12">
						<a-col flex="1">
							<a-input v-model="modalAgent.formdata.payment" placeholder="请输入保证金" :disabled="!modalAgent.bond.userId" />
						</a-col>
						<a-col>
							<a-button @click="onCheckPayment">查询</a-button>
						</a-col>
					</a-row>
					<p slot="help" v-if="modalAgent.bond.userId">
						当前用户有保证金 {{ modalAgent.bond.userBond }}元 还需补交 {{ modalAgent.bond.payment }}元
					</p>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</a-space>
</template>

<script>
	import moment from 'moment';
	import VueViewer from 'vue-viewerjs';
	
	const countyData = require('@/assets/address.json')
	const formatter = 'YYYY-MM-DD';
	const DEFAULT_QUERY = {
		page: 1,
		size: 10,
		userId: undefined,
		status: undefined,
		mobile: undefined,
		beginTime: undefined,
		endTime: undefined
	};

	export default {
		components: { VueViewer },
		data() {
			return {
				countyData,
				query: Object.assign({}, DEFAULT_QUERY),
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				},

				modal: {
					visible: false,
					data: {}
				},
				
				modalAgent: {
					visible: false,
					formdata: {
						mobile: undefined,
						province: undefined,
						city: undefined,
						county: undefined,
						payment: undefined
					},
					rules: {
						mobile: { required: true, message: '请输入用户手机号' },
						county: { required: true, message: '请选择区县' },
						payment: { required: true, message: '请输入保证金' },
					},
					bond: {
						userId: '',
						nickname: '',
						userBond: '',
						payment: 0
					}
				},
			};
		},
		computed: {
			selectedDateRange() {
				const { beginTime, endTime } = this.query;
				if (beginTime && endTime) {
					return [moment(beginTime, formatter), moment(endTime, formatter)];
				} else {
					return null;
				}
			}
		},
		async mounted() {
			this.getAgents();
			// const response = await this.$api.get('/user_query_product/2');
			// this.goodsOptions = (response && response.data) || [];
		},
		methods: {
			async getAgents() {
				const response = await this.$api.get('/agent_rebate_list', {
					params: this.query
				});
				if (response && response.code == 200) {
					this.lists = Object.assign(this.lists, response.data);
				}
			},
			async onPageChange(pagination) {
				this.query.page = pagination.current;
				this.query.size = pagination.pageSize;
				this.getAgents();
			},
			async onSearch() {
				this.query.page = 1;
				this.getAgents();
			},
			async onReset() {
				this.query = Object.assign({}, DEFAULT_QUERY);
				this.getAgents();
			},
			async onChange(o, dateStr) {
				if (dateStr.length === 2) {
					this.query.beginTime = dateStr[0];
					this.query.endTime = dateStr[1];
				} else {
					this.query.beginTime = undefined;
					this.query.endTime = undefined;
				}
			},
			async onAgents() {
				const response = await this.$api.get('/agent_user_list', {
					params: this.modalAgent.query
				});
				if (response.code == 200) {
					Object.assign(this.modalAgent.lists, response.data);
					this.modalAgent.visible = true
				}
			},
			async onAgentPageChange(pagination) {
				this.modalAgent.query.page = pagination.current;
				this.modalAgent.query.size = pagination.pageSize;
				this.onAgents();
			},
			async onExport() {
				this.$store.dispatch('setLoading', true);
				const response = await this.$api.get('/agent_rebate_export', {
					params: this.query,
					responseType: 'arraybuffer'
				});
			},
			async onViewOrder(record) {
				const response = await this.$api.get('/vip_order_detail/' + record.orderId);
				if (response.code == 200) {
					this.modal.data = Object.assign({}, record, response.data);
					this.modal.visible = true;
				}
			},
			
			async onCreate() {
				Object.assign(this.modalAgent.formdata, {
					mobile: undefined,
					province: undefined,
					city: undefined,
					county: undefined,
					payment: undefined
				})
				Object.assign(this.modalAgent.bond, {
					userId: '',
					nickname: '',
					userBond: '',
					payment: 0
				})
				this.modalAgent.visible = true
			},
			async onCheckPayment() {
				const { mobile } = this.modalAgent.formdata
				if (/^1\d{10}$/.test(mobile)) {
					const response = await this.$api.get('/agent_get_bond', {
						params: { mobile }
					});
					if (response && response.code == 200) {
						Object.assign(this.modalAgent.bond, response.data);
					}
					// 18168552845
				}
			},
			
			async onSaveAgent() {
				this.$refs.form.validate(async valid => {
					if (valid) {
						const response = await this.$api.post('/agent_add', this.modalAgent.formdata);
						if (response && response.code == 200) {
							this.$message.success('操作成功');
							this.getAgents();
							this.modalAgent.visible = false;
						} else {
							this.$message.error(response.msg);
						}
					}
				});
			}
		}
	};
</script>